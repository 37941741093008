<template>
<div id="channel">
  <b-tabs v-model="tabIndex" position="is-centered" type="is-boxed" size="is-medium">
    <b-tab-item label="渠道列表">
      <b-field position="is-centered" class="box">
        <b-select v-model="channelBodyType" @input="onChannelTypeSelect">
          <option value="null">全部</option>
          <option v-for="typ in channelBodyTypes"
            :value="typ.value"
            :key="typ.value">
            {{typ.text}}
          </option>
        </b-select>
      </b-field>
      <b-table :data="channels"
        paginated backend-pagination
        :per-page="perPage"
        :total="total"
        :current-page="currentPage"
        @page-change="onPageChange">
        <b-table-column #default="props" field="status" label="状态" width="60" centered>
          <b-tag :type="props.row.status===1?'is-success':'is-light'">{{ props.row.status===1?'已启用':'已禁用'}}</b-tag>
        </b-table-column>
        <b-table-column #default="props" field="cid" label="Channel ID" width="40" numeric>
          {{ props.row.cid }}
        </b-table-column>
        <b-table-column #default="props" field="name" label="渠道名称" width="200">
          {{ props.row.name }}
        </b-table-column>
        <b-table-column #default="props" field="bodytype" label="载体类型" width="120">
          {{ channelTypeName(props.row.bodytype) }}
        </b-table-column>
        <b-table-column #default="props" field="rs" label="所属区服" width="120">
          {{ props.row.rs }}
        </b-table-column>
        <b-table-column #default="props" label="参数字符串">
          {{ props.row.path }}
        </b-table-column>
        <b-table-column #default="props" field="createtime" label="创建时间" centered>
          {{ formatDateTime(props.row.createtime) }}
        </b-table-column>
        <b-table-column #default="props" field="note" label="备注">
          {{ props.row.note }}
        </b-table-column>
        <b-table-column #default="props" label="操作">
          <div class="buttons">
            <button v-if="hasQR(props.row.bodytype)" class="button is-success" @click="onQRClick(props.row)">二维码</button>
            <button :disabled="isSpecial(props.row.cid)" class="button is-info" @click="onEditClick(props.row)">修改</button>
            <button :disabled="isSpecial(props.row.cid)" class="button is-danger" @click="onDeleteClick(props.row)">删除</button>
          </div>
        </b-table-column>
        <template #bottom-left>
          <b>总计：</b>{{ total }}
        </template>
        <template #empty>
          <p class="has-text-centered">没有内容。</p>
        </template>
      </b-table>
    </b-tab-item>
    <b-tab-item label="增加渠道">
      <channel-editor ref="channelAdd" @channel-edit="onChannelAddSave"/>
    </b-tab-item>
    <b-tab-item label="编辑渠道" :disabled="editType!=='edit'">
      <channel-editor ref="channelEdit" is-edit="true" @channel-edit="onChannelEditSave"/>
    </b-tab-item>
    <b-tab-item label="二维码" :disabled="editType!=='qr'">
      <div class="container">
        <figure>
          <img :src="qrimage" :alt="qrtitle" width="430" height="430"/>
        </figure>
        <div class="tag is-primary">{{qrtitle}}</div>
        <div class="content">{{qrpath}}</div>
      </div>
    </b-tab-item>
  </b-tabs>
</div>
</template>

<script>
import ChannelEditor from '@/components/channel/ChannelEditor'
import type from '@/core/type'
import config from '@/core/config'
import cache from '@/core/cache'

export default {
  name: 'channel',
  components: { ChannelEditor },
  created: function () {
    this.getChannels()
  },
  computed: {
    channelBodyTypes: function () {
      return type.channelBodyTypes()
    }
  },
  data: function () {
    return {
      perPage: config.PER_PAGE,
      currentPage: 1,
      total: 0,
      tabIndex: 0,
      channels: [],
      editType: null,
      qrimage: null,
      qrtitle: '',
      qrpath: '',
      channelBodyType: null
    }
  },
  methods: {
    onPageChange (page) {
      this.currentPage = page
      this.getChannels(page)
    },
    onChannelTypeSelect (select) {
      this.getChannels()
    },
    hasQR: function (bodytype) {
      return [211, 212, 213].includes(bodytype)
    },
    isSpecial (cid) {
      return [1, 500].includes(cid)
    },
    onEditClick (channel) {
      this.$refs.channelEdit.setValues(channel)
      this.tabIndex = 2
    },
    onDeleteClick (channel) {
      const msg = `是否要删除渠道 <strong>${channel.name}</strong> ？`
      this.$buefy.dialog.confirm({
        title: '删除渠道',
        type: 'is-danger',
        message: msg,
        onConfirm: () => {
          this.mjp.post2({
            url: '/channel/del/',
            data: { cid: channel.cid }
          }).then(data => {
            this.hub.alert(`删除渠道 ${channel.name} 成功！`)
            this.tabIndex = 0
          })
        }
      })
    },
    async onQRClick (channel) {
      const r = cache.getR()
      if (r === null) {
        this.hub.alert('请选择区服 Regional！', 2, 'is-danger')
        return
      }
      try {
        const qrInfo = await this.mjp.get2({ url: '/channel/qrcode/wechatminiapp/', query: { cid: channel.cid }, mjpType: 'admin' })
        this.mjp.post2({
          url: '/wechat/miniapp/qrcode/',
          data: qrInfo.qr,
          query: { r },
          responseType: 'blob',
          mjpType: 'auth'
        }).then(data => {
          this.qrtitle = `${channel.name} (${channel.cid})`
          this.qrpath = channel.path
          const reader = new FileReader()
          // 期待返回 'image/jpeg'
          const isJSON = data.type === 'application/json'
          reader.onload = (evt) => {
            // 伪装成 Blob 的  JSON  。通常是因为调用需要返回文件的API的时候出错了
            if (isJSON) {
              console.log('target.result %s', evt.target.result)
              const msg = JSON.parse(evt.target.result).message
              this.hub.alert(`获取二维码失败： ${msg}`, 3, 'is-danger')
            } else {
              this.qrimage = evt.target.result
              this.tabIndex = 3
            }
          }
          if (isJSON) {
            reader.readAsText(data)
          } else {
            reader.readAsDataURL(data)
          }
        })
      } catch (e) {
        console.error(e)
      }
    },
    channelTypeName (bodytype) {
      return type.getTypeName(bodytype)
    },
    getChannels (page) {
      page = page || this.currentPage || 1
      this.mjp.get('/channel/get/more/',
        {
          bodytype: this.channelBodyType,
          page: page,
          per_page: this.perPage
        }).then(data => {
        this.channels = data.channels
        this.currentPage = data.page
        this.perPage = data.per_page
        this.total = data.total
      })
    },
    onChannelAddSave (channel) {
      this.mjp.post('/channel/add/', channel).then(data => {
        this.hub.alert(`增加渠道 ${data.channel.name} 成功`)
        this.tabIndex = 0
      })
    },
    onChannelEditSave (channel) {
      this.mjp.post('/channel/edit/', channel).then(data => {
        this.hub.alert(`修改渠道 ${data.channel.name} 成功`)
        this.tabIndex = 0
      })
    }
  },
  watch: {
    tabIndex (to, from) {
      if (from === 2) {
        this.$refs.channelEdit.clearValues()
      }
      if (from === 1) {
        this.$refs.channelAdd.clearValues()
      }
      if (from === 3) {
        this.qrimage = null
        this.qrpath = null
        this.qrtitle = null
      }
      if (to === 0) {
        this.editType = null
        this.getChannels()
      } else if (to === 1) {
        this.editType = 'add'
        this.$refs.channelAdd.initRegionals()
      } else if (to === 2) {
        this.editType = 'edit'
      } else if (to === 3) {
        this.editType = 'qr'
      }
    }
  }
}
</script>

<style>
#channel {
  padding: 12px;
}
</style>
