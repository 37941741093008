<template>
  <div id="channeledit">
    <b-field horizontal label="渠道ID" grouped>
      <div>
      <b-input name="cid" v-model.number="channelID" :disabled="isEdit" placeholder="请按使用范围选择id，不可与现有id重复"></b-input>
      <ul class="has-text-left">
        <li>团队成员: 2-99</li>
        <li>特殊渠道: 500-999</li>
        <li>公司员工: 100-499</li>
        <li>免费渠道: 10000-19999</li>
        <li>付费渠道: 20000-29999</li>
      </ul>
      </div>
    </b-field>
    <b-field horizontal label="状态" style="text-align:left">
      <b-switch v-model.number="channelStatus" true-value=1 false-value=5>{{channelStatus === 1?'启用':'禁用'}}
      </b-switch>
    </b-field>
    <b-field horizontal label="渠道名称">
      <b-input name="name" v-model.trim="channelName"></b-input>
    </b-field>
    <b-field horizontal label="备注">
      <b-input name="note" v-model.trim="channelNote"></b-input>
    </b-field>
    <b-field horizontal label="渠道载体类型">
      <b-select v-model="channelBodyType">
        <option v-for="typ in channelBodyTypes"
          :value="typ.value"
          :key="typ.value">
          {{typ.text}}
        </option>
      </b-select>
    </b-field>
    <b-field horizontal label="选择区服">
      <regional-selector ref="regionalSelector" @select="regionalSelect" :includeTypes="[5000, 2000, 1000]" />
    </b-field>
    <b-field horizontal label="支持区服设置">
      <b-select native-size="10" multiple v-model="selectedRegionals" expanded>
        <option value="0">【全部】</option>
        <option v-for="(o, index) in regionals" :key="index" :value="o.r">({{o.r}}){{ o.name }}</option>
      </b-select>
    </b-field>
    <b-field grouped position="is-centered">
      <button class="button is-danger" :disabled="!canSave" @click="onSaveChannel">保存</button>
    </b-field>
  </div>
</template>

<script>
import type from '@/core/type'
import RegionalSelector from '@/components/RegionalSelector'

export default {
  components: { RegionalSelector },
  computed: {
    canSave () {
      const s = this.channelName &&
        this.channelID &&
        this.channelBodyType &&
        this.selectedRegionals.length > 0
      return s
    },
    channelBodyTypes () {
      return type.channelBodyTypes()
    }
  },
  // 编辑管理员则值为 true， 增加管理员则值为 false
  props: ['isEdit'],
  data () {
    return {
      channelID: null,
      channelName: '',
      channelNote: null,
      channelBodyType: null,
      channelStatus: 1,
      regionals: [],
      selectedRegionals: []
    }
  },
  mounted () {
    this.initRegionals()
  },
  methods: {
    initRegionals () {
      this.$refs.regionalSelector.getRecords()
    },
    regionalSelect (regionalList) {
      this.regionals = regionalList
    },
    setValues (channel) {
      this.channelID = channel.cid
      this.channelName = channel.name
      this.channelNote = channel.note
      this.channelBodyType = channel.bodytype
      this.channelStatus = channel.status
      this.selectedRegionals = channel.rs
      this.initRegionals()
    },
    clearValues () {
      this.channelID = null
      this.channelName = ''
      this.channelNote = null
      this.channelBodyType = null
      this.selectedRegionals = []
      this.channelStatus = 1
    },
    onSaveChannel () {
      // 如果 Regional 多选，但其中包含 0 这个值，则删除其他值
      if (parseInt(this.selectedRegionals[0]) === 0) {
        this.selectedRegionals = ['0']
      }
      const channelObj = {
        cid: this.channelID,
        name: this.channelName,
        bodytype: this.channelBodyType,
        rs: this.selectedRegionals,
        status: this.channelStatus
      }
      if (this.channelNote) channelObj.note = this.channelNote
      this.$emit('channel-edit', channelObj)
    }
  }
}
</script>
